import axios from "axios";

const PPEapi = axios.create({
  baseURL: "https://mizube.oud.ai/api/users", // Replace with your actual API base URL
  // baseURL: "http://127.0.0.1:5000/api/users", // Replace with your actual API base URL
  headers: {
    "Content-Type": "application/json", // Default headers
  },
});

export default PPEapi;
